<template>
  <section class="@container" data-cy-organization-member-creation-form>
    <header class="mb-4">
      <h3 class="font-bold">
        {{
          $t("organization_member.creation_form.title", {
            organization: profile?.organization.name,
          })
        }}
      </h3>
    </header>

    <app-form
      class="grid gap-4 @xl:grid-cols-[35%_auto_auto_auto]"
      @submit="onSubmit"
    >
      <app-form-input-text
        v-model="form.email"
        autofocus
        data-cy-email
        :label="$t('app.form.labels.professional_email')"
        required
        type="email"
      />
      <app-form-input-text
        v-model="form.first_name"
        data-cy-first-name
        :label="$t('app.form.labels.first_name')"
        required
      />
      <app-form-input-text
        v-model="form.last_name"
        data-cy-last-name
        :label="$t('app.form.labels.last_name')"
        required
      />
      <app-form-input-text
        v-model="form.job_title"
        data-cy-job-title
        :label="$t('app.form.labels.job_title')"
        required
      />

      <div class="mt-2 flex justify-end gap-2 @xl:col-span-4">
        <app-button color="dark" variant="tonal" @click="$emit('cancel')">
          {{ $t("app.cta.cancel") }}
        </app-button>
        <app-button color="dark" type="submit">
          {{ $t("app.cta.add") }}
        </app-button>
      </div>
    </app-form>
  </section>
</template>

<script lang="ts" setup>
import type { OrganizationMemberCreationRequest } from "~~/model/organization.model";

const properties = defineProps<{
  initialState?: Partial<OrganizationMemberCreationRequest>;
}>();

const emit = defineEmits<{
  submit: [form: OrganizationMemberCreationRequest];
  cancel: [];
}>();

const { profile } = useOrganizationMemberProfile();

const form = reactive<OrganizationMemberCreationRequest>({
  email: "",
  first_name: "",
  last_name: "",
  job_title: "",
  ...properties.initialState,
});

function onSubmit() {
  emit("submit", form);
}
</script>
