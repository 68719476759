<template>
  <app-form-autocomplete
    v-model="model"
    v-model:query="queryModel"
    :autofocus="autofocus"
    :exclude="exclude"
    hide-label
    hide-selected
    item-text="item.user.full_name"
    item-value="item.id"
    :items="results"
    label="Members"
    multiple
    :placeholder="placeholder"
    prepend-icon="ph:magnifying-glass"
    :readonly="readonly"
    @close="$emit('close')"
    @open="$emit('open')"
  >
    <template #item="{ item }">
      <user-identity-tile
        :avatar-size="36"
        class="text-sm"
        :user="item.item.user"
      />
    </template>

    <template v-if="$slots.empty" #empty="{ close }">
      <slot name="empty" v-bind="{ close, refresh }" />
    </template>
  </app-form-autocomplete>
</template>

<script lang="ts" setup>
import { useFuse } from "@vueuse/integrations/useFuse";

import type { I18nMessage } from "~/core/i18n/i18n.model";
import type { OrganizationMemberView } from "~~/model/organization.model";

const properties = withDefaults(
  defineProps<{
    modelValue?: OrganizationMemberView[];
    query?: string;
    autofocus?: boolean;
    exclude?: DatabaseTable<"organization_members">["id"][];
    readonly?: boolean;
    placeholder?: I18nMessage;
  }>(),
  {
    modelValue: undefined,
    exclude: undefined,
    query: "",
    placeholder: undefined,
  },
);

const queryModel = useVModel(properties, "query", undefined, {
  passive: true,
  defaultValue: "",
});

const emit = defineEmits<{
  "update:modelValue": [value: typeof properties.modelValue];
  open: [];
  close: [];
}>();

defineSlots<{
  empty(properties: { close(): void; refresh(): void }): void;
}>();

const model = computed<DatabaseTable<"organization_members">["id"][]>({
  get() {
    return properties.modelValue?.map((member) => member.id) ?? [];
  },
  set(value) {
    emit(
      "update:modelValue",
      value
        .map((id) => data.value?.find((member) => member.id === id))
        .filter(Boolean),
    );
  },
});

const { profile } = useOrganizationMemberProfile();

const { data, refresh } = useFetch<OrganizationMemberView[] | null>(
  `/api/organizations/${profile.value?.organization_id}/members`,
);

const { results } = useFuse(
  queryModel,
  computed(() => data.value ?? []),
  {
    fuseOptions: {
      keys: ["user.first_name", "user.last.name", "user.full_name"],
      threshold: 0.3,
    },
    matchAllWhenSearchEmpty: true,
  },
);
</script>
