import type {
  OrganizationMemberView,
  RegistrationRequestView,
} from "~~/model/organization.model";

export function useRegistrationRequestCreationHook({
  session_id,
}: {
  session_id: DatabaseTable<"course_sessions">["id"];
}) {
  const { t } = useI18n();
  const { $emit } = useNuxtApp();
  const { profile, hasRole } = useOrganizationMemberProfile();
  const submitting = ref(false);

  async function create(
    {
      members,
      comment,
    }: {
      members: OrganizationMemberView[];
      comment: string;
    },
    options?: {
      onSuccess(payload: RegistrationRequestView[]): void;
    },
  ) {
    if (!profile.value) return;

    submitting.value = true;

    const requests: RegistrationRequestView[] = await Promise.all(
      members.map((member) =>
        $fetch(
          `/api/organizations/${profile.value!.organization_id}/members/${
            member.id
          }/requests`,
          {
            method: "POST",
            body: {
              session_id,
              comment,
            },
          },
        ),
      ),
    );

    submitting.value = false;
    if (hasRole("MEMBER")) {
      useToastNotification().showSuccess(
        t("registration_request.confirmation_message.as_member", {
          count: members.length,
        }),
      );
    }
    options?.onSuccess(requests);

    return $emit("registration_request_created", {
      session_id,
    });
  }

  return { create, submitting };
}
